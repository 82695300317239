/* Case.css */

body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
}

.step6 {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    max-width: 800px;
    margin: 40px auto;
}

.product {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
}

.products {
    font-size: 32px;
    text-decoration: underline;
    color: #333;
}

.form_section {
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
}

.form_align {
    display: flex;
    flex-direction: column;
}

#contact_form {
    display: flex;
    flex-direction: column;
}

#contact_form input,
#contact_form textarea {
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

#contact_form textarea {
    min-height: 100px;
    resize: vertical;
}

#contact_form input:focus,
#contact_form textarea:focus {
    border-color: #007bff;
    outline: none;
}

button[type="submit"] {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

.recapcha {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.recapcha span {
    margin-right: 10px;
    font-size: 16px;
    color: #333;
}

.wrapper {
    max-width: 600px;
    margin: 0 auto;
}
