/* >>  PROGET1  << */


.websitefont{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  

/* >>  MENU  << */



.navbar1{
  color: black;
}
.navelogo{
  width: 65px;
  height: 65px;
  border-radius: 5%;
  background-color:white;
  border: 1px solid black;
  padding: 3px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}
.navehead{
  text-decoration: none;
  color: rgb(3, 3, 158);
}
.navehead1{
  text-decoration: none;
  color:black;
}
.navehead1 p{
  margin: 0;
}
.navehead h4{
  margin: 0;
}
.gst{
  color:limegreen;
}
.naveicon{
  color: black;
  width: 18px;
  height: 18px;
}
.naveicon1{
  text-decoration: none;
  padding: 0 20px 0 0;
}
.navebtn{
  width: 55px;
}
.navbaritoms {
  overflow: hidden;
  background-color:white;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.navbaritoms a h6{
  margin: 0;
}
.navbaritoms a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 10px 15px;
  text-decoration: none;
}
.navbaritoms a:hover {
  color: white;
  background-color: rgb(3, 3, 158);
}
.navbaritoms2 a {
  float: right;
  display: block;
  color: white;
  background-color:rgb(3, 3, 158);
  text-align: center;
  text-decoration: none;
}
.navbaritoms2 a:hover {
  color: rgb(0, 0, 0);
}


/* >>  HOME   << */


.scrolling a{
  text-decoration: none;
  color:rgb(3, 3, 158) ;
}
.srcimg{
  width: 100px;
  height: 200px;
  padding: 10px;
}
.Subtitle1{
  color:white ;
  background-color: rgb(3, 3, 158);
  padding: 5px;
  margin: 0;
}
.Subtitle2{
  font-size: 20px;
  text-align: center;
  color:  rgb(3, 3, 158);
  padding: 5px;
  margin: 0;
}
.Subtitle21{
  font-size: 25px;
  text-align: center;
  color:  rgb(3, 3, 158);
  padding: 5px;
  margin: 0;
}


/* >>  Bottom   << */












.top{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wh{
  width: 100%;
}
.img1{
 width: 99%;
}
.toph{
  color: #5A189A;
}

.cat{
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.btn1 {
  background-color: blueviolet; /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
}



.cards{
  border: none;
  text-align: center;
  width: 200px;
}
.crdimg25{
   width: 245px;
   box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}


.card1{
 padding:20px ;
  width: 120px;
}

.crdimg2{
  width: 100%;
}
.form_section {
    margin: 20px;
}


ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 10px;
}

li:last-child {
  border-bottom: none;
}

.wrapper {
    max-width: 600px;
    margin: 0 auto;
}

.form_align {
    display: flex;
    flex-direction: column;
}

#contact_form input[type="text"],
#contact_form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

#contact_form textarea {
    height: 100px;
}

.recapcha {
    margin-bottom: 10px;
}

.recapcha span {
    margin-right: 10px;
}

button {
    padding: 10px 20px;
    background-color:lightseagreen;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 200px;
}

.contactloc{
  background-color:  #5A189A ;
  color: white;
  padding: 15px;
}
.body {
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
}

header {
  text-align: center;
  padding: 5px;
}

.Links2 {
  display: flex;
  flex-direction: column;
}
.Links2 a{
  color: black;
  text-decoration: none;
}
.gmap{
width: 400px;
height: 400px;
border:none;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
margin-top: -19px;
}

.locimg{
  width: 260px;
  height: 200px;
  padding-left: 35px;
}
.head2{
  text-align: center;
  color: black;
}
.head3{
  text-align: center;
  color: #EBC9FF;
}

.top5{
  display: flex;
  justify-content: center;
}

.cards1 {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.hidden {
  display: none;
}
.iphone{
  color: white;
  padding: 10px;
}
.ibo{
  color: black;
  padding-right: 5px;
}
.iconsim{
  text-decoration: none;
}
.container11 {
  display: flex;
}
.categories{
  width: 300px;
}
.tabs11{
  flex: 1;
}

.cards11 {
  flex: 2;
  border: none;
}

.card11 {
  display: none;
}

.footerpage {
  background-color:#10002B;
  color: white;
  text-align: center;
  padding: 5px;
  bottom: 0;
  width: 100%;
}
.as{
  color: orangered;
}
.con{
  width: 5px;
  color: white;
}
.mail{
  text-decoration: none;
  color: white;
}
.txt{
  text-decoration: none;
  color: #10002B;
}
