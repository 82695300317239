.homecard{
    width: 270px;
    /* border:2px solid red; */
}
.sizeadjust img{
    width: 270px;
    height: 200px;
}
.sizeadjust{
    text-align: center;
    /* border:2px solid red; */
    padding-left: 50px;
}
.imgsize{
    text-align: center;
}
.imgsize img{
    height:230px;
}
.navelogobottom {
      height:90px;
      width: 90px;
      margin-left: 3px;
      /* border:2px solid red; */
}

.contact{
    font-size: 25px;
}
.qualityhead{
    font-size: 28px;
    /* font-weight: bold; */
}
.qualityfull{
    /* border:2px solid red; */
    margin-left: 2px;
    padding: 1px;
}

