.modal-title {
    font-family: 'Times New Roman', serif; /* Change to Times New Roman */
    font-weight: bold;
  }
  
  .table th, .table td {
    font-family: 'Times New Roman', serif; /* Change to Times New Roman */
  }
  
  .modal-footer {
    font-family: 'Times New Roman', serif; /* Change to Times New Roman */
  }
  