/* Style.css */
.thome1 {
    padding: 5px;
}

.topcard {
    border: none;
}

.topcard .img1 {
    width: 100%;
}

.topcard .aboutsty {
    padding: 20px;
    margin-top: 7px;
}

.topcard .qualityhead {
    font-size: 20px;
    font-weight: bold;
}

.topcard .qualitycontent {
    margin-top: 10px;
}
