.productimgsize img{
    height:240px;
    width: 308px;
}
/* Default styles for larger screens */
.thome {
    display: flex;
    padding: 1rem;
}

.product {
    margin-left: 50px;
}
.categories a{
    text-decoration: none;
    color: aliceblue;
}

.categories ul {
    list-style: none;
    padding: 0;
}

.categories li {
    margin-bottom: 10px;
}

.categories button {
    width: 100%;
    padding: 10px;
    text-align: left;
}

.productimgsize {
    padding: 1rem;
}

.cards11 .card11 {
    margin-bottom: 1rem;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .thome {
        flex-direction: column;
    }

    .product {
        margin-left: 0;
        text-align: center;
    }

    .categories button {
        text-align: center;
    }

    .productimgsize {
        padding: 0.5rem;
    }

    .cards11 .card11 {
        margin-bottom: 0.5rem;
    }

    .categories {
        padding: 1rem;
    }
    .dis{
        display: flex;
    }
    .productimgsize img{
        padding-left: 50px;
        height:230px;
        width:300px;
    }

}

  