.websitefont {
    font-family: Arial, sans-serif;
}

.Topnavebar {
    background-color: #f8f9fa;
    padding: 10px 0;
}

.navelogo {
    max-width: 100px;
}
.navhead img{
    height:50px;
     width:480px;
}
.navehead1, .navehead .gst {
    /* font-weight: 800; */
    color: #000;
    text-decoration: none;
}

.navehead1 p {
    margin: 0;
}

.contact-info {
    display: flex;
    gap: 20px;
    align-items: center;
}

.contact-item {
    display: flex;
    align-items: center;
    padding: 10px;
}

.contact-icon {
    height: 50px;
    width: 50px;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .d-flex.align-items-center.justify-content-between {
        flex-direction: column;
        align-items: flex-start;
    }

    .contact-info {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
    }

    .contact-item {
        width: 100%;
        margin-bottom: 10px;
    }
    .navhead img{
          height: 50px;
          width: 260px;
}  
}